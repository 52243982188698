// src/components/EventCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './EventCard.css';

const EventCard = ({ event }) => {
    return (
        <div className="event-card">
            <img src={event.image} alt={event.name} className="event-card-image"/>
            <div className="event-card-content">
                <h2 className="event-card-title">{event.name}</h2>
                <p className="event-card-date">{event.date}</p>
                <p className="event-card-number">{event.number}</p>
                <p className="event-card-description">{event.description}</p>
                <Link to={`/events/${event.name}`} className="event-card-link">View Details</Link>
            </div>
        </div>
    );
};

export default EventCard;
