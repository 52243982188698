// src/components/EventDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import './EventDetail.css';
import { Link } from 'react-router-dom';

const EventDetail = () => {
    const { eventName } = useParams();

    // Define the events with their details
    const events = [
        { 
            name: 'TalentsOfTomorrow', 
            description: `
            <h2>🎤 Talents of Tomorrow – A fost o seară magică! 🌟</h2>
            <p>Talents of Tomorrow s-a încheiat cu succes, și suntem extrem de fericiți pentru toți cei care au fost alături de noi! Am avut o seară vibrantă, plină de energie și emoție, unde talentul și creativitatea au strălucit pe scenă. 💫</p>
            <p>Mulțumim tuturor artiștilor care au împărtășit momentele lor deosebite și publicului minunat care a creat o atmosferă de neuitat! 🥳</p>
            <p>Dacă ai ratat evenimentul sau vrei să retrăiești momentele speciale, nu te îngrijora! Poți viziona cele mai frumoase clipe pe canalul nostru de YouTube. 🎥✨</p>
            <p>Rămâneți cu noi pentru următoarele aventuri pline de magie și inspirație! 💥</p>

                            `,
            image: require('./assets/TalentsOfTomorrow.png'),
            signupLink: 'https://www.youtube.com/watch?v=nObbBhyYL1Q&t=7s'
        },        {
            name: 'ELF Quizz',
            date:'2024-10-05',
            number: '#2',
            description:`
            <p>🎉 Elf Quizz a fost un eveniment fantastic dedicat Zilei Mondiale a Educației! 🌍✨ Ne-am adunat pentru a celebra importanța educației și a încuraja participanții să își testeze cunoștințele într-un mod distractiv și interactiv.</p>
            <p>Cu întrebări variate și provocatoare, am creat o atmosferă plină de entuziasm și competiție prietenoasă. Participanții au avut ocazia să învețe lucruri noi și să își demonstreze abilitățile, totul într-un cadru plin de energie pozitivă! 🧠💡</p>
            <p>Mulțumim tuturor celor care au fost alături de noi la acest eveniment special! Ne-am bucurat de fiecare moment petrecut împreună și de toată pasiunea voastră pentru educație. 🌟📚</p>
            <p>Rămâneți conectați pentru următoarele activități și provocări pe care le pregătim!</p>

            `,
            image: require('./assets/ELFQuizz.png'),
            signupLink: 'https://www.instagram.com/reel/DAv2e_TtJK6/'
        },
        {
            name: 'Afaceri Întunecate',
            date: '2024-11-02',
            number: '#1',
            description: `
            <p>🔍 Afaceri Întunecate: Un Eveniment de Tip Murder Mystery 🔪</p>
            <p>Mulțumim tuturor participanților care au fost alături de noi la Afaceri Întunecate, o noapte plină de intrigi, trădări și secrete dezvăluite! Evenimentul exclusiv de tip Murder Mystery a oferit o experiență unică, captivându-vă în lumea plină de tensiune a unei familii puternice și influente.</p>

            <p>🕵️ Povestea: <br>
            Amelia Led, capul renumitei familii și acționarul major al companiei internaționale, a fost găsită moartă în circumstanțe misterioase, iar suspiciunile s-au îndreptat asupra celor apropiați. Averea, influența și secretele întunecate ale familiei au ieșit la lumină, iar datorită spiritului de observație al participanților, vinovatul a fost demascat!</p>

            <p>💼 Ne bucurăm că ați fost alături de noi în această aventură, fie că ați venit în echipă sau ați avut ocazia să cunoașteți noi prieteni și colegi detectivi. Fiecare a contribuit cu indicii și teorii ingenioase care au făcut ca povestea să prindă viață într-un mod special.</p>

            <p>🎟️ Ne dorim să vă revedem și la viitoarele noastre evenimente! Vă mulțumim încă o dată pentru implicare, creativitate și pentru donațiile oferite. Fără voi, Afaceri Întunecate nu ar fi avut același farmec și succes! </p>

            <p>Așteptăm cu nerăbdare următoarea aventură!</p>
            `,
            image: require('./assets/AfaceriIntunecate.png'),
            signupLink: 'https://www.instagram.com/reel/DB8xY-WNBnB/'
        },

    ];

    // Find the event based on the eventName parameter
    const event = events.find(event => event.name === eventName);

    if (!event) {
        return <p>Event not found</p>;
    }

    return (
        <div className="event-detail">
            <h1>{event.name}</h1>
            <img src={event.image} alt={event.name} className="event-image"/>
            {event.date && (
            <p><strong>Data:</strong> {event.date}</p>
            )}
            {event.hour && (
            <p><strong>Ora:</strong> {event.hour}</p>
            )}
            {event.location && (
            <p><strong>Locație:</strong> {event.location}</p>
            )}

            <div dangerouslySetInnerHTML={{ __html: event.description }} />
            <a href={event.signupLink} className="signup-link">Îndrăznește să fii altfel!!</a>
            <div>
            <Link className="back-link" to="/events">Back</Link>
            </div>
        </div>
        
    );
};

export default EventDetail;
