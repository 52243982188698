import React from 'react';
import EventCard from './EventCard';
import './Events.css';

const Events = () => {
    const events = [
        {
            name: 'TalentsOfTomorrow',
            date: '2024-09-13',
            number: '#1',
            description: 'Un spectacol open stage vibrant și plin de energie unde orice formă de artă e binevenită! Scena e a ta!',
            image: require('./assets/TalentsOfTomorrow.png')
        },
        {
            name: 'ELF Quizz',
            date:'2024-10-05',
            number: '#2',
            description: 'Elf Quizz este un eveniment dedicat Zilei Mondiale a Educației. Scopul acestui eveniment este de a promova educația și de a încuraja participanții să își testeze cunoștințele într-un mod distractiv și interactiv.',
            image: require('./assets/ELFQuizz.png')
        },
        {
            name: 'Afaceri Întunecate',
            date: '2024-11-02',
            number: '#3',
            description: 'Afaceri Întunecate este un eveniment de tip Murder Mystery plin de intrigi și secrete. Ești invitat să descoperi cine este vinovatul în această poveste captivantă! ',
            image: require('./assets/AfaceriIntunecate.png')
        },
        
    ];

    return (
        <div className="events">
            <h1>Evenimente</h1>
            {events.map((event, index) => (
                <EventCard key={index} event={event} />
            ))}
        </div>
    );
};

export default Events;
